@import "~@tn/web-uikit/scss/variables/index";

.sim-assign-succeeded {
  max-width: 360px;
}

.device-check-sim-assign-succeeded {

  .device-check-sim-assign-succeeded__subtext {
    margin-top: 20px;
    margin-bottom: 46px;
  }

  button.uikit-button--inverted.uikit-button--branded.device-checker-button {
    margin-bottom: 79px;
    margin-left: unset;
  }
}

@media (max-width: $uikit-media-breakpoint-max-sm) {
  .device-check {
    .device-check-sim-assign-succeeded {
      margin: unset;

      h1 {
        width: 290px;
        margin: auto;
      }

      .device-check-sim-assign-succeeded__subtext {
        width: 280px;
        margin-bottom: 36px;
      }

      button.uikit-button--inverted.uikit-button--branded.device-checker-button {
        margin-bottom: 28px;
        width: 100%;
        text-transform: capitalize;
      }
    }
  }
}

