@import "~@tn/web-uikit/scss/variables/index";
@import '~@tn/web-uikit/scss/mixins/index';
@import '~@tn/web-uikit/scss/typography/index';

.price-label {
  line-height: 14px;
  display: flex;
  justify-content: flex-start;
  position: relative;
}

.price-label--muted {
  @extend .uikit-muted;
}

.price-label--danger {
  color: $uikit-danger;
}

.price-label--cross-out {
  span {
    text-decoration: line-through;
  }
}

.price-label__sup-text {
  position: absolute;
  top: 0;
}

.price-label__sub-text {
  margin-top: 6px;
}

.price-label--big, .price-label--jumbo {
  .price-label__dollar {
    @extend .uikit-text--regular;
    font-size: 60px;
    line-height: 55px;
    margin-left: 2px;
    font-weight: 300;
  }

  .price-label__sub-text {
    margin-top: 10px;
  }

  .price-label__sup-text {
    position: relative;
    margin-top: 8px;
  }
}

@media (max-width: $uikit-media-breakpoint-max-md) {
  .price-label--big {
    .price-label__dollar {
      font-size: 37px;
      line-height: 33px;
    }

    .price-label__sub-text {
      margin-top: 5px;
    }
  }

  .price-label__sup-text {
    line-height: 10px;
  }
}
