@import '~@tn/web-uikit/scss/variables/index';

// Variables

// Colours
$linkBoxBorderColor: #ddd;
$linkTileTextColor: #4a4a4a;
$faded-grey: rgba(43,46,56,0.52);
$green-color1: rgb(51, 137, 78);
$grey-color1: #949494;

// Modal
$modalTextPrimary: #3a424b;

// Text
$textPrimary: #323A45;
$textSecondary: #9399A3;
$textTertiary: #9399A3;
$body: #6E7A88;
$link: #5094F9;
$facebookLoginBackground: #3D57A5;

// Layout
$black: #000000;
$white: #FFFFFF;
$blue: #00B0ED;
$deeperBlue: #488AEC;
$grey: #3D5663;
$lightGrey: #F5F7FB;
$lightGreen: #00ac85;
$promo-red: #FB476C;
$detailBlue: #5190FB;
$backgroundGrey: #f8f8f8;
$modalBlockerColor: rgba(43,46,56,0.26);

//$primary: $uikit-primary;

$textboxBorderColor: #A7ABB3;
$textboxBorderColor2: #e5e6e7;

// Response
$happy: #0AD1A3;
$error: $promo-red;

// Fonts
$OpenSans: 'Open Sans', Arial, sans-serif;
$sourceSansPro: 'Source Sans Pro', 'Helvetica', Arial, sans-serif;
$montserrat: 'Montserrat', 'Helvetica', Arial, sans-serif;
$lato: 'Lato', sans-serif;
$roboto: 'Roboto', sans-serif;
$helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;

$headerHeight: 0px;
$footerHeight: 407px;
$footerBareHeight: 88px;

// Borders
$divider: 8px solid #F5F7FB;

// Spacers
$spacerMTiny: 6px;
$spacerMxsmall: 12px;
$spacerMsmall: 18px;
$spacerMmedium: 24px;
$spacerMlarge: 36px;
$spacerMxlarge: 45px;
$spacerMxxlarge: 72px;

$spacerTiny: 6px;
$spacerXsmall: 12px;
$spacerSmall: 18px;
$spacerMedium: 24px;
$spacerLarge: 36px;
$spacerXlarge: 45px;
$spacerXxlarge: 90px;

$hover-shadow: 0 2px 20px 0 rgba(0,0,0,0.14);
$hover-shadow-alt: 0 -2px -20px 0 rgba(0,0,0,0.14);

$maxContentWidth: 1170px;
$mobileBreakWidth: 700px;

// Margins
$margin-small: 24px;
$margin-x-small: 16px;
$margin-tiny: 8px;

// Responsiveness
$screen-sm-min: 699px;
$screen-super-sm: 350px;
