@import "~@tn/web-uikit/scss/variables/index";

.sim-assign-failed {
  max-width: 360px;
}

.device-check-sim-assign-failed {

  h1 {
    width: 750px;
    margin: auto;
  }

  .subText {
    margin-top: 21px;
  }

  button.uikit-button--inverted.uikit-button--branded.device-checker-button {
    margin: 22px 16px 78px 16px;
    text-transform: capitalize;
  }
}

@media (max-width: $uikit-media-breakpoint-max-sm) {

  .device-check-sim-assign-failed {

    h1 {
      letter-spacing: -1px;
      width: 280px;
    }

    .subText {
      margin-top: 16px;
      width: 280px;
    }

    button.uikit-button--inverted.uikit-button--branded.device-checker-button {
      margin: 8px 8px 8px 0px;
      text-transform: capitalize;

      &.check-another-phone {
        margin-bottom: 24px;
      }
    }
  }
}
