@import '~@tn/web-uikit/scss/variables/index';
@import '../../theme/variables';

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
        visibility: visible;
    }
    to {
        opacity: 0;
        visibility: visible;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .ei10up {
        display: none !important;
    }
}

#pai-icon-chat-bubble-call-to-action {
    font-family: 'Open Sans', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.container {
    padding: 0;
}

.device-checker-page {
    overflow: hidden;

    h1 {
        font-family: $helvetica;
        margin-left: auto;
        margin-right: auto;
    }

    .device-checker-page_ditch-section {
        margin-left: 101px;
    }

    .device-checker-page__get-plan-margin {
        margin-top: 71px;
    }

    .device-checker-page__ditch_column {
        margin-top: -45px;
    }

    .device-checker-page__hand-image {
        max-width: 592px;
        margin-left: 55px;
    }

    .device-checker-page__get_a_plan-column {
        max-width: 700px;
        text-align: center;
    }

    .device-checker-page__ready-section-margin {
        margin-top: 65px;

        a {
            text-decoration: none;
        }
    }

    .device-checker-page__heading_text h1 {
        line-height: 45px;
        font-weight: 400;
        font-size: 35px;
    }

    .device-checker-page__ditch-width {
        width: 333px;
    }

    .device-checker-page__paragraph_text {
        width: 315px;
        font-size: 16px;
        line-height: 30px;
    }

    .device-checker-page__button {
        text-transform: capitalize;
        margin-top: unset;
        margin-bottom: 93px;
    }

    @media (max-width: $uikit-media-breakpoint-max-md) {
        .device-checker-page__ditch_column {
            width: 270px;
        }
    }

    @media (max-width: $uikit-media-breakpoint-max-sm) {
        .device-checker-page_ditch-section {
            margin-left: unset;
        }

        .device-checker-page__hand-image {
            max-width: 592px;
            margin-left: unset;
        }

        .device-checker-page__mobile-background {
            background: #f8f8f8;
            margin-left: -106px;
            margin-right: -106px;
            padding-left: 106px;
            padding-right: 106px;
        }
        .device-checker-page__ditch_column {
            text-align: center;
        }

        .device-checker-page__heading_text h1 {
            font-size: 30px;
            font-weight: 300;
            margin: 48px auto 0 auto;
            line-height: 1.47;
        }

        .device-checker-page__get-plan-heading h1 {
            font-size: 30px;
            margin: 36px auto 0 auto;
            line-height: 1.4;
            margin-bottom: 16px;
            width: 200px;
        }

        .device-checker-page__paragraph_text {
            margin: 15px auto 0 auto;
            width: 260px;
            font-size: 14px;
            line-height: 26px;
        }

        .device-checker-page__get-plan-margin {
            margin-top: 40px;
        }

        .device-checker-page__get_a_plan-column {
            padding: 0 8px;
            width: 270px;
        }

        .device-checker-page__ready-section-margin {
            margin-top: unset;
        }

        .device-checker-page__ready-heading h1 {
            font-size: 25px;
            margin-bottom: 32px;
            margin-top: 83px;
        }

        .device-checker-page__ditch-width {
            width: unset;
        }
    }
}

/* Updated data add ons (2021) */
/* Layout: Tables */
.add-ons__table {
    border: 1px solid #c9c9c9;
    box-sizing: border-box;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    margin: 0 auto 40px;
    max-width: 1080px;
    width: 100%;
}

.add-ons__table--data {
    margin-top: 94px;
}

.add-ons__table--green {
    background-color: #e8f5eb;
    border-color: #94c7a4;
}

.add-ons__table--purple {
    background-color: #efe8ff;
    border-color: #8149f8;
}

.add-ons__table-group {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1080px;
    width: 100%;
}

.add-ons__table-group .add-ons__table {
    margin: 0;
    width: calc(50% - 20px);
}

@media screen and (max-width: $uikit-media-breakpoint-min-xs) {
    .add-ons__table--data,
    .add-ons__table-group {
        max-width: calc(100% - 32px);
        margin: 40px 16px;
    }

    .add-ons__table-group {
        display: block;
    }

    .add-ons__table-group .add-ons__table {
        width: 100%;
    }

    .add-ons__table-group .add-ons__table:nth-child(1) {
        margin-bottom: 40px;
    }
}

/* Layout: Rows */
.add-ons__row {
    box-sizing: border-box;
    display: flex;
}

.add-ons__row--bordered {
    border-bottom: 1px solid #c9c9c9;
}

/* Layout: Columns */
.add-ons__column {
    box-sizing: border-box;
    flex: 1;
    padding: 40px 24px 24px;
}

.add-ons__column--bordered {
    border-right: 1px solid #c9c9c9;
}

@media screen and (max-width: 1023px) {
    .add-ons__column {
        padding: 24px 24px 10px;
    }
}

.add-ons--border-green {
    border-color: #94c7a4;
}

.add-ons--border-purple {
    border-color: #8149f8;
}

/* Layout: Data groups */
.add-ons__group {
    display: flex;
    flex: 1;
}

@media screen and (min-width: 1024px) {
    .add-ons__group:nth-child(2) .add-ons__column--bordered:nth-child(2) {
        border: 0;
    }
}

@media screen and (max-width: 1023px) {
    .add-ons__row--data {
        flex-direction: column;
    }

    .add-ons__group .add-ons__column--bordered:nth-child(2) {
        border-right: 0;
    }

    .add-ons__group:nth-child(2) {
        border-top: 1px solid #c9c9c9;
    }
}

@media screen and (max-width: 559px) {
    .add-ons__group {
        flex-direction: column;
    }

    .add-ons__column--bordered {
        border-right: 0;
    }

    .add-ons__group .add-ons__column--bordered:nth-child(2) {
        border-top: 1px solid #c9c9c9;
    }
}

/* Text */
.add-ons__heading {
    color: #000;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 50px;
    margin: 0;
}

.add-ons__text {
    color: #000;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 30px;
    margin: 0 0 16px;
}

.add-ons__price {
    color: #000;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 80px;
    margin: 0;
}

.add-ons__text--green {
    color: #33894e;
}

.add-ons__text--purple {
    color: #8149f8;
}

@media screen and (max-width: 639px) {
    .add-ons__heading {
        font-size: 24px;
    }

    .add-ons__price {
        font-size: 24px;
        line-height: 50px;
    }
}

/* Layout: Spaces the price to the bottom of a column. */
.add-ons__row--max-height {
    flex: 1;
}

.add-ons__row--max-height .add-ons__column {
    display: flex;
    flex-direction: column;
}

.add-ons__row--max-height .add-ons__details {
    flex: 1;
}
