@import "~@tn/web-uikit/scss/variables/index";

.device-content__featured__image {
  padding: 0;

  &:not(:last-of-type) {
    padding-right: $uikit-spacing-small;
  }

  img {
    width: 100%;
  }

  @media (max-width: $uikit-media-breakpoint-max-sm) {
    &:not(:last-of-type) {
      padding-right: 0;
    }
  }
}

@media (max-width: $uikit-media-breakpoint-max-sm) {
  .device-content__featured {
    flex-direction: column;
    padding-right: 0;
    padding-bottom: $uikit-spacing-small;
  }
}
