@import "~@tn/web-uikit/scss/variables/index";

@media (max-width: $uikit-media-breakpoint-max-xs) {
  .product-set__buy-now--fixed {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: $uikit-white;
    z-index: $uikit-zindex-base + 2;
  }
}

.product-set__buy-now {
  .product-set__paypal-wrapper {
    a {
      display: block;
      height: 48px;
      width: 76px;
    }
  }
}
