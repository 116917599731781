@import '~@tn/web-uikit/scss/variables/index';
@import "../../../../theme/_variables.scss";

.banner-icons {
  &.banner-icons--inverted {
    background-color: var(--bg-color, $uikit-primary);
    --fg-color: white;
  }

  &.banner-icons--alternate {
    background-color: $uikit-grey5;
  }

  .banner-icons__tile {
    min-height: 118px;
    align-items: center;
    color: $body;
    color: var(--fg-color, $body);
    width: 100%;
  }

  .banner-icons__tile__icon {
    width: 48px;
    height: 48px;

    &.banner-icons__tile__icon--regular {
      margin-right: 10px;
      width: 74px;
    }
  }

  .banner-icons__tile__label {
    max-width: 120px;
  }

  @media screen and (max-width: $uikit-media-breakpoint-max-md) {
    .banner-icons__tile__icon {
      margin-right: 5px;
    }
  }
}
