@import "../../../../theme/_variables.scss";
@import '~@tn/web-uikit/scss/variables/index';

.plans-modal__footer {
  max-width: 470px;
  margin: 0 auto;
}

@media (max-width: $uikit-media-breakpoint-max-md) {
  .plans-modal__footer {
    max-width: 100%;
  }
}
