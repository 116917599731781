@import "~@tn/web-uikit/scss/variables/index";
@import '../../../../theme/variables';

.phone-compatibility {
  max-width: 320px;
}

@media (max-width: $uikit-media-breakpoint-max-sm) {
  .phone-compatibility {
    max-width: 100%;
  }
}

.device-check {
  background-color: $uikit-primary;
  margin: auto;
  padding-bottom: 68px;

  h1 {
    font-family: $helvetica;
    color: rgb(255, 255, 255);
    font-size: 90px;
    line-height: 100px;
    text-align: center;
    margin: 0;
  }

  h2 {
    font-family: $helvetica;
    font-weight: 400;
    color: rgb(255, 255, 255);
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    margin: 0;
    margin-top: 58px;
    margin-bottom: 13px;
  }

  .phone-compatibility__thick-font {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
  }

  .subText {
    margin-top: 5px;
    font-family: $helvetica;
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 0.5px;
    line-height: 30px;
    text-align: center;

    &.subText-link {
      font-weight: 400;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .esn-submit-form {
    margin-top: 46px;
  }

  .uikit-text-field--error .uikit-text-field__message {
    width: 100%;
    background-color: #D80000;
    color: white;
    padding: 4px 12px;
  }

  .device-checker-button--opacity {
    :hover {
      opacity: 0.8;
    }
    :focus, :active {
      opacity: 0.6;
    }
  }

  button.uikit-button--inverted.uikit-button--branded.device-checker-button {
    margin-left: 32px;
    background-color: white;
    color: #8149f8;
    margin-top: 0;
    text-transform: initial;

    :hover, :active, :focus {
      color: #8149f8;
      opacity: 1;
    }

    .uikit-text:hover {
      color: #8149f8;
      background-color: white;
    }
  }

  #sim-card-esn {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 60px $uikit-primary inset;
      -webkit-text-fill-color: white;
      caret-color: white;
    }
  }

  .esnInput {
    border-radius: 4px;
    height: 52px;
    width: 328px;
    input {
      color: white;
      background-color: $uikit-primary;
      border: 1px white solid;
    }

    .uikit-text-field__label {
      color: white;
      opacity: 0.9;
    }

    .uikit-text-field__input {
      font-size: 16px;
    }
  }
  .need-help__link {
    margin-top: 48px;
    margin-bottom: -16px;
  }

  .device-check-sim-assign-succeeded {
    max-width: 900px;
    margin: auto;
  }
}

@media (max-width: $uikit-media-breakpoint-max-sm) {
  .device-check {
    padding-bottom: 24px;

    h1 {
      font-size: 35px;
      line-height: 40px;
    }
    h2 {
      font-size: 25px;
      line-height: unset;
      margin-top: 31px;
      margin-bottom: 8px;
    }

    .subText {
      font-size: 14px;
      line-height: 25px;
      width: 220px;
      margin: auto;
    }

    .esnInput {
      width: 100%;
      margin-bottom: 46px;
    }

    .phone-compatibility__thick-font {
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 1px;
    }

    button.uikit-button--inverted.uikit-button--branded.device-checker-button {
      margin-left: unset;
      margin-top: 16px;
    }

    .esn-submit-form {
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 32px;
    }

    .need-help__link {
      margin-top: 8px;
      margin-bottom: 0px;

      .subText {
        font-weight: 200;
      }
    }
    .phone-not-compatible-button-group {
      flex-direction: column;
    }
  }
}
