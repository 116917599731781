@import "~@tn/web-uikit/scss/variables/index";

.device-content__specs {
  border-top: 1px solid $uikit-grey3;

  .device-content__specs__btn-container {
    position: absolute;
    margin-top: -65px;
    left: calc(50vw - 70px);

    .device-content__specs__btn {
      transition: all 0.3s;
      transform: rotate(180deg);

      &.device-content__specs__btn--opened {
        transform: rotate(0deg);
      }

      &.device-content__specs__btn--closed {
        transform: rotate(180deg);
      }
    }
  }
}
