.sale-price {
  color: red;
}

.related-products-tile {
  width: 100%;

  &:hover, &:focus {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
}
