@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; visibility: visible; }
  to   { opacity: 0; visibility: visible; }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ei10up {
    display: none !important;
  }
}

#pai-icon-chat-bubble-call-to-action {
  font-family: 'Open Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.phone-message-label {
  max-width: 500px;
}