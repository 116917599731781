@import "~@tn/web-uikit/scss/variables/index";

.product-set {
  border-top: 2px solid $uikit-grey4;
  position: relative;
  overflow: hidden;

  .row {
    height: 100%;
  }

  .product-set__divider {
    margin: 15px 0;
  }
  .product-set__preview {
    background: $uikit-grey5;
  }

  .product-set__sim-preview {
    @extend .product-set__preview;
    padding-right: 0;
    overflow: hidden;
  }
}

.product-set__right {
  position: relative;
}

@media (min-width: $uikit-media-breakpoint-max-sm) {
  .product-set {
    min-height: 739px;
    .row {
      min-height: 739px;
    }

    .product-set__divider{
      display: none;
    }
  }
}
