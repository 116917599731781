@import "../../../../theme/_variables.scss";
@import '../../../../../node_modules/@tn/web-uikit/scss/variables/index';

.modal-dialog {
  &.phone-compatibility-modal-device-checker {
    width: 646px;
    max-width: unset;

    h2 {
      margin-top: 48px;
      font-size: 35px;
      font-weight: 400;
      font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
    }

    .phone-compatibility-modal__button {
      text-transform: capitalize;
      margin-bottom: 37px;
    }

    .modal-content {
      background: white;
      padding: 10px 10px;
      font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      border-radius: 0.5rem;

      .phone-compatibility-modal_heading-os {
        margin-top: 22px;
        color: rgb(108, 109, 117);
      }
    }

    .uikit-text-heading2 {
      font-size: 28px;
    }

    .iphone-instructions {
      margin-right: 64px;
      width: 40%;
    }

    .android-instructions {
      width: 40%;
    }
  }
}

@media (max-width: $uikit-media-breakpoint-max-sm) {

  .modal-dialog {
    &.phone-compatibility-modal-device-checker {
      width: unset;
      margin-left: 5%;
      margin-right: 5%;
      margin-bottom: unset;

      .modal-content {
        font-size: 14px;
        line-height: 26px;

        .phone-compatibility-modal_heading-os {
          margin-top: 6px;
        }
      }

      .iphone-instructions {
        font-size: 14px;
        width: 100%;

        .uikit-text--bold {
          font-size: 14px;
        }
      }

      .android-instructions {
        margin-top: 18px;
        font-size: 14px;
        width: 100%;

        .uikit-text--bold {
          font-size: 14px;
        }
      }

      .rt-modal__close {
        display: none;
      }

      .phone-compatibility-modal__button {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .instruction-container {
        flex-direction: column;
      }
    }
  }
}

