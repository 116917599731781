@import "../../theme/_variables.scss";
@import "~@tn/web-uikit/scss/variables/index";

.super-banner {
  background: #FCFAFA;
}

.super-banner__link {
  display: flex;
  justify-content: center;
}

.super-banner__link {
  & img {
    width: 100%;
    height: 100%;
  }
}

.super-banner__content {
  height: 145.3vw;
  max-height: inherit;
}

@media (min-width: $uikit-media-breakpoint-max-sm) {
  .super-banner__content {
    max-height: 432px;
    height: 36.9vw;
  }
}
@media (min-width: $uikit-media-breakpoint-max-xs) {
  .super-banner__link--mobile {
    display: none;
  }
}

@media (max-width: $uikit-media-breakpoint-max-xs) {
  .super-banner__link--desktop {
    display: none;
  }
}
