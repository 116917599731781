@import "../../../../theme/_variables.scss";
@import '~@tn/web-uikit/scss/variables/index';

.plans-modal .modal-content {
  background: $backgroundGrey;
  padding: 24px 10px;
  color: $modalTextPrimary;
}

@media (max-width: $uikit-media-breakpoint-max-md) {
  .plans-modal {
    max-width: 98%;
  }

  .plans-modal .modal-content {
    padding: 0;
  }

  .plans-modal__footer {
    max-width: 100%;
  }
}

.free-plans-banner {
  border: 2px solid $uikit-primary;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
  color: $uikit-primary;
  font-family: $helvetica;
  text-align: center;
  padding : $margin-x-small;
}

@media (max-width: $uikit-media-breakpoint-max-md) {
  .free-plans-banner .uikit-text--regular{
    font-size: 14px;
    line-height: 18px;
  }
}
