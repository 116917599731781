@import "~@tn/web-uikit/scss/variables/index";

.pop-out {
  position: absolute;
  left: 50px;

  bottom: -100px;
  width: 350px;
  border-radius: 4px;
  background-color: $uikit-danger;
  color: $uikit-white;
  z-index: $uikit-zindex-zopim-chat + 1; // matches the zopim chat z-index
  opacity: 0;

  transition: all .2s;

  @media (max-width: $uikit-media-breakpoint-max-sm) {
    position: fixed;
  }

  &.opened {
    opacity: 1;
    bottom: 20px;

    @media (max-width: $uikit-media-breakpoint-max-sm) {
      left: 50%;
      transform: translate(-50%,-50%);

      width: 90%;
      bottom: 50px;
    }
  }
}
