@import "../../../../theme/_variables.scss";
@import '~@tn/web-uikit/scss/variables/index';

.plans-modal__tile {
  background: $white;

  padding: 23px 20px 25px 20px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05);
}

.plans-modal--promo {
  color: $promo-red;

  .price-label__sup-text {
    color: $promo-red;
  }
}

.plans-modal__tile__description {
  min-height: 140px;
  border-bottom: 1px solid #ededee;
}

.label--strong {
  font-weight: bold;
}

@media (max-width: $uikit-media-breakpoint-max-md) {
  .plans-modal__tile {
    padding: 15px;
  }
}
