@import '../../../../theme/variables';
@import "~@tn/web-uikit/scss/variables/index";

.sim-page {
  overflow: hidden;
  .sim-page__section-margin {
    margin-top: 100px;
  }

  .sim-content-column-width {
    max-width: 469px;
  }

  .sim-content-need-data {
    margin-bottom: 68px;
  }

  .sim-page__phone-image {
    max-width: 454px;
  }

  .sim-page__logo-image {
    position: relative;
    left: -216px;
  }

  .sim-page__logo-image-mobile {
    min-width: 320px;
  }

  .sim-page__plan-price {
    .sim-page__vertical-divider {
      margin-left: 36px;
      margin-right: 36px;
      border-right: 1px solid #d8d8d8;
    }
  }

  .sim-page__faq {
    margin-bottom: 100px;
    .sim-page__faq-title {
      margin-top: 24px;
      padding-bottom: 8px;
    }

    .sim-page__faq-content {
      border-bottom: 1px solid #f1f1f1;
      padding-bottom: 24px;
    }
  }

  .sim-page__reviews-content {
    margin-top: 48px;
    .sim-testimonial {
      position: relative;

      .review-icon {
        position: absolute;
        top: 0;
        left: -80px;
      }

      .sim-testimonial-content {
        max-width: 305px;
      }
    }
  }

  @media (max-width: $uikit-media-breakpoint-max-sm) {
    .sim-content-column-width {
      max-width: unset;
    }

    .sim-content-need-data {
      margin-bottom: unset;
    }

    .sim-page__reviews-content {
      margin-top: 0;
      .sim-testimonial {
        .sim-testimonial-content {
          max-width: none;
        }
      }
    }
  }

  @media (min-width: $uikit-media-breakpoint-min-sm) {
    .faq-heading__flex-margin{
      margin-right: 50%;
    }
  }

  @media (min-width: $uikit-media-breakpoint-min-md) {
    .sim-testimonial-content__left-side-margin {
      margin-left: 20px;
    }
    .sim-testimonial-content__right-side-margin {
      margin-left: 60px;
    }
    .sim-heading__column-margin{
      margin-left: 5px;
    }
    .faq-heading__column-margin{
      margin-left: 1%;
    }
    .faq-heading__flex-margin{
      margin-right: 66%;
    }
  }

  @media (min-width: $uikit-media-breakpoint-min-lg) {
    .sim-testimonial-content__left-side-margin {
      margin-left: 48px;
    }
    .sim-testimonial-content__right-side-margin {
      margin-left: 60px;
    }
    .sim-heading__column-margin{
      margin-left: 78px;
    }
    .faq-heading__column-margin{
      margin-left: 14%;
    }
    .faq-content__flex-margin{
      margin-right: 12%;
    }
  }
}
