@import '~@tn/web-uikit/scss/variables/index';

.related-products {
  background-color: $uikit-grey5;

  .related-products-title {
  }

  .related-products-button {
  }
}
