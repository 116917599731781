@import "~@tn/web-uikit/scss/variables/index";

.tile-image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.45);
  align-self: center;
  display: flex;
  justify-content: center;
  left: 0;
  margin: 0 !important;
}

.tile-image-overlay__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  text-align: center;

  background-color: white;
  color: #6E7A88;
  width: 171px;
  height: 57px;
  border-radius: 4px;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,0.2);
}

@media (max-width: $uikit-media-breakpoint-max-md) {
  .tile-image-overlay-md--none {
    display: none;
  }

  .tile-image-overlay-md {
    display: flex;
  }

  .tile-image-overlay__container {
    width: 96%;
  }
}

@media (max-width: $uikit-media-breakpoint-max-sm) {
  .tile-image-overlay-sm--none {
    display: none;
  }
}
