@import "../../../../theme/_variables.scss";
@import '../../../../../node_modules/@tn/web-uikit/scss/variables/index';

.phone-compatibility-modal .modal-content {
  background: $backgroundGrey;
  padding: 24px 10px;
  color: $modalTextPrimary;
}

@media (max-width: $uikit-media-breakpoint-max-md) {
  .phone-compatibility-modal {
    max-width: 98%;
  }

  .phone-compatibility-modal .modal-content {
    padding: 0;
  }
}
