@import "~@tn/web-uikit/scss/variables/index";

.device-content-specs {
  width: 100%;
  max-width: 880px;
  transition: max-height .5s ease;
  overflow: hidden;
  max-height: 0;

  &.device-content-specs--opened {
    max-height: 999px;
  }

  .device-content-specs__item {
    width: 405px;
    border-top: 1px solid $uikit-grey3;

    .device-content-specs_description {
      max-width: 290px;
      text-align: right;
      color: $uikit-grey2;
    }
  }
}

.device-content-specs__container {
  justify-content: space-between;
}

@media (max-width: $uikit-media-breakpoint-max-md) {
  .device-content-specs {
    .device-content-specs__item {
      width: 350px;

      .device-content-specs_description {
        max-width: unset;
      }
    }
  }
}

@media (max-width: $uikit-media-breakpoint-max-sm) {
  .device-content-specs__container {
    justify-content: center;
  }

  .device-content-specs {
    .device-content-specs__description {
      max-width: 210px;
    }
  }
}
