$uikit-image-base-path: '/common/public/images/web-uikit/';
@import "~@tn/web-uikit/scss/uikit.scss";
@import "~@tn/web-uikit/scss/variables/index";

body {
  margin: 0;
  padding: 0;
  background: #fff;
}

.main {
  padding: 0;
  min-height: 100vh;
}

.grecaptcha-badge {
  z-index: $uikit-zindex-base + 2;
}

// Webflow uses a fixed position navbar so everything needs to move down its height
.webflowWrapperV1 {
  margin-top: 84px;
}
