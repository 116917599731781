@import "~@tn/web-uikit/scss/variables/index";

.phone-locked {
  max-width: 420px;

  .phone-locked__left-button {
    margin-right: $uikit-spacing-small;
  }
}

@media (max-width: $uikit-media-breakpoint-max-xs) {
  .phone-locked {
    .phone-locked__left-button {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}