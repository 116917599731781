@import '../../theme/variables';
@import "~@tn/web-uikit/scss/variables/index";

.tile {
  box-sizing: border-box;
  height: 439px;
  width: 100%;
  transition: box-shadow 0.4s;
  color: $textPrimary;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover, &:focus {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
}

@media (max-width: $uikit-media-breakpoint-max-lg) {
  .tile {
    height: auto;
    min-height: 360px;
  }
}

@media (max-width: $uikit-media-breakpoint-max-md) {
  .tile {
    margin-top: 10px;
    min-height: 220px;
    border-bottom: solid 1px #f1f2f6;
  }
}
