@import "~@tn/web-uikit/scss/variables/index";

.tech-review-banner {
  background-image: linear-gradient(43deg, rgba(255,255,255,0.5),rgba(79,79,79,0.54) 69%,rgba(0,0,0,0.56)),linear-gradient(#373737,#373737);

  color: $uikit-white;
  height: 406px;
  justify-content: center;
  align-items: center;

  .tech-review-banner__message {
    max-width: 750px;
    margin: 0 auto;
  }
}

.tech-review-banner__message__big {
  line-height: 40px;
  font-weight: normal !important;
}

@media (max-width: $uikit-media-breakpoint-max-sm) {
  .tech-review-banner {
    text-align: center;
  }

  .tech-review-banner__message__big {
    line-height: 30px;
  }
}
