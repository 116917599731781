@import "~@tn/web-uikit/scss/variables/index";

.FAQ-title {
  text-align: center;
}

.FAQ__more {
  transition: max-height .5s ease;
  overflow: hidden;
  max-height: 0;

  &.FAQ__more--opened {
    max-height: 1024px;
  }
}
