@import "~@tn/web-uikit/scss/variables/index";

.tile__image {
  height: 380px;
  align-self: center;
  position: relative;
  display: flex;
  padding: 0 19px;

  img {
    width: 100%;
    max-height: 100%;
    align-self: center;
    object-fit: contain;
  }

  /* this resets the padding when using an overlay */
  div {
    margin: 0 -19px;
  }
}

.tile__image--disabled {
  opacity: 0.45;
}

@media (max-width: $uikit-media-breakpoint-max-lg) {
  .tile__image {
    div {
      margin: auto;
    }
  }
}

@media (max-width: $uikit-media-breakpoint-max-md) {
  img {
    width: auto;
  }
  .tile__image {
    height: 100%;
    padding: 0;
  }
}
