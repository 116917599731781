@import "../../theme/variables";
@import "~@tn/web-uikit/scss/variables/index";

.tile__content {
  display: flex;
  flex-direction: column;
  align-self: center;
  min-height: 270px;
  justify-content: space-between;
  width: 100%;
}

.tile__content__header {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .row {
    width: 100%;
  }
}

.tile__content__detail {
  &>* {
    margin-bottom: 5px;
  }
}
@media (max-width: $uikit-media-breakpoint-max-md) {
  .tile__content {
    min-height: 140px;
  }

  .tile__content__detail__header {
    display: none;
  }
}

@media (max-width: $uikit-media-breakpoint-max-sm) {
  .tile__content {
    min-height: 200px;
  }
}

@media (max-width: $uikit-media-breakpoint-max-xs) {
  .tile__content {
    min-height: 154px;
  }
}

