@import "../../../../../node_modules/@tn/web-uikit/scss/variables/index";

.product-set__select-plan {
  max-width: 320px;
}

.product-set__plan__price {
  color: $uikit-primary;
}

@media (max-width: $uikit-media-breakpoint-max-sm) {
  .product-set__select-plan {
    max-width: 100%;
  }
}
